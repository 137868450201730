import React from 'react'
import Box from '@src/components/atoms/Box'
import ContactBelt from '@src/components/molecules/ContactBelt'
import HeaderWithImage from '@src/components/molecules/HeaderWithImage'

const jobText =
  'We are always welcome who want to tackle with us. Please contact us now!'

const JobPage = ({  }: any) => (
  <div className="jobPage">
    <HeaderWithImage />
    <h1 className="pageTitle">Work with us!!</h1>
    <Box>
      <p
        style={{
          textAlign: 'center',
        }}
        dangerouslySetInnerHTML={{ __html: jobText }}
      />
    </Box>
    <ContactBelt />
  </div>
)

export default JobPage
